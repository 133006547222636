import GameLink from "./GameLink";

function Links() {
  return (
    <div className="links-container">
      <GameLink
        href="https://store.steampowered.com/app/2194640/Project_Electric_Sheep/"
        text="PLAY NOW ON STEAM!"
      />
      <style jsx>{`
        .links-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }
      `}</style>
    </div>
  );
}

export default Links;
