import React from "react";
import Snowflake from "./Snowflake";

class Snow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numFlakes: 120,
    };
  }

  render() {
    const arr = new Array(this.state.numFlakes).fill("");
    const snow = arr.map((el, i) => {
      return <Snowflake key={i} id={i} />;
    });
    return (
      <>
        <div className="Snow">{snow}</div>
        <style jsx>{`
          .Snow {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            overflow: hidden;
            height: 15vh;
          }
        `}</style>
      </>
    );
  }
}

export default Snow;
