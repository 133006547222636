import {
  FaSquareXTwitter as XTwitterIcon,
  FaEnvelope as EnvelopeIcon,
  FaTiktok as TikTokIcon,
  FaInstagram as InstagramIcon,
  FaDiscord as DiscordIcon,
} from "react-icons/fa6";

import SocialIconLink from "./SocialIconLink";

const socialsList = [
  {
    href: "mailto:hello@charisma.ai",
    ariaLabel: "Email address for Project Electric Sheep",
    icon: <EnvelopeIcon size="28px" />,
  },
  {
    href: "https://discord.com/invite/2KW3N4p",
    ariaLabel: "Charisma Discord profile",
    icon: <DiscordIcon size="28px" />,
  },
  {
    href: "https://www.tiktok.com/@charisma.ai",
    ariaLabel: "Charisma TikTok profile",
    icon: <TikTokIcon size="28px" />,
  },
  {
    href: "https://twitter.com/aicharisma",
    ariaLabel: "Charisma X (Twitter) profile",
    icon: <XTwitterIcon size="28px" />,
  },
  {
    href: "https://instagram.com/charisma.ai",
    ariaLabel: "Charisma Instagram profile",
    icon: <InstagramIcon size="28px" />,
  },
];

function Socials() {
  return (
    <div className="horizontal-container">
      {socialsList.map((social) => (
        <SocialIconLink service={social} key={social.href} />
      ))}
      <style jsx>{`
        .horizontal-container {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-bottom: 32px;
        }
        .social-links {
          display: flex;
          margin: 15px 0;
        }
      `}</style>
    </div>
  );
}

export default Socials;
