import Link from "next/link";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-link">
          <Link href="https://charisma.ai/" style={{ color: "white" }}>
            © 2017-2023 Charisma Entertainment Ltd{" "}
          </Link>
        </div>
        <div className="footer-link">
          <Link href="/privacy" style={{ color: "white" }}>
            Privacy Policy
          </Link>
        </div>
        <div className="footer-link">
          <Link href="/terms" style={{ color: "white" }}>
            Terms and Conditions
          </Link>
        </div>
      </div>

      <style jsx>{`
        .footer {
          background: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;
          width: 100%;
        }

        .footer-content {
          display: flex;
          justify-content: center;
          width: 90%;
          max-width: 1000px;
          margin: auto;
        }

        .footer-link {
          padding: 14px;
          display: block;
          font-size: 14px;
          margin: 20px;
        }

        @media (max-width: 768px) {
          .footer-link {
            margin: 20px 0;
          }
        }
      `}</style>
    </div>
  );
}

export default Footer;
