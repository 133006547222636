function MainVideo() {
  return (
    <>
      <div className="aspect-ratio-box">
        <iframe
          className="aspect-ratio-box-content"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/-b23QI34KwY"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Trailer for Project Electric Sheep"
        />
      </div>
      <div className="glow" />
      <style jsx>{`
        .aspect-ratio-box {
          padding-top: 56.25%;
          max-width: 100%;
          position: relative;
          z-index: 1;
          margin: 60px 0;
        }

        .aspect-ratio-box-content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: black;
          border-radius: 6px;
          box-shadow: 0px 2px 10px #06d0dd;
        }

        .glow {
          filter: blur(20px);
          background: conic-gradient(
            from 230.29deg at 51.63% 52.16%,
            #06d0dd 0deg,
            #09fedd 360deg
          );
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
        }
      `}</style>
    </>
  );
}

export default MainVideo;
