import React from "react";

class Snowflake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animationDelay: "0s",
      fontSize: "10px",
    };
    this.generateSnowflake = this.generateSnowflake.bind(this);
  }

  componentDidMount() {
    this.generateSnowflake();
  }

  generateSnowflake = () => {
    const newDelay = `${(Math.random() * 16).toFixed(2)}s`;
    const newFontSize = `${Math.floor(Math.random() * 10) + 10}px`;
    this.setState({ animationDelay: newDelay, fontSize: newFontSize });
  };

  render() {
    const { animationDelay, fontSize } = this.state;
    const style = { animationDelay, fontSize };
    return (
      <>
        <p className="Snowflake" id={`item${this.props.id}`} style={style}>
          -
        </p>
        <style jsx>{`
          .Snowflake {
            display: inline-block;
            width: 0.1%;
            color: #fffafa;
            opacity: 0;
            font-size: 120px;
            margin: 0;
            padding: 0;
            animation: fall 18s linear infinite;
            filter: blur(2px);
          }

          @keyframes fall {
            0% {
              opacity: 0;
            }
            1% {
              opacity: 0.21;
            }

            50% {
              opacity: 0.013;
            }
            100% {
              transform: translate(0, 15vh);
              opacity: 0;
            }
          }
          /* added small blur every 6 snowflakes*/
          .Snowflake:nth-child(3n) {
            filter: blur(3px);
          }
        `}</style>
      </>
    );
  }
}

export default Snowflake;
