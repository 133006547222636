type GameLinkProps = {
  href: string;
  text: string;
};

function GameLink({ href, text }: GameLinkProps) {
  return (
    <div className="link-container">
      <a href={href} style={{ color: "#a4e7ff" }}>
        <div className="clickable">{text}</div>
      </a>
      <style jsx>{`
        .link-container {
          display: flex;
          justify-content: center;
          padding: 12px;
        }
        .clickable {
          justify-content: center;
          margin: auto;
          width: 260px;
          background-color: rgba(0, 0, 0, 0.85);
          padding: 6px 12px;
          color: #01ffff;
          display: flex;
          align-items: center;
          border: 2px solid #a4e7ff;
          border-radius: 10px;
          box-shadow: 0px 2px 10px #a4e7ff;
          font-size: 18px;
          text-align: center;
          transition: all 0.2s ease-in-out;
        }
        .clickable:hover {
          box-shadow: 0 5px 100px rgba(92, 145, 182, 0.4);
        }
      `}</style>
    </div>
  );
}

export default GameLink;
