import Image from "next/image";

import Links from "@/components/Links";
import Footer from "@/components/Footer";
import MainVideo from "@/components/MainVideo";
import Snow from "@/components/Snow";
import Socials from "@/components/Socials";

import Screenshot1 from "@/public/static/pes-screenshot-3.png";
import Screenshot2 from "@/public/static/pes-screenshot-4.png";

function HomePage() {
  return (
    <main>
      <Snow />
      <div className="darken">
        <div className="content">
          <div className="title">
            <Image
              src="/static/pes-logo.png"
              width={1040}
              height={142}
              alt="Logo for Project Electric Sheep, consisting of the text 'Project Electric Sheep'."
              style={{ maxWidth: "90vw", maxHeight: "12vw" }}
              priority
            />
            <div className="tagline">
              <div className="tag-text">
                Conversational dream simulator created by
              </div>
              <a href="https://charisma.ai" style={{ margin: "14px" }}>
                <Image
                  src="/static/charisma-logo-dark-mode.svg"
                  width={179}
                  height={40}
                  alt="Logo for Charisma.ai, consisting of a green alien head with the text 'Charisma.ai'."
                />
              </a>
            </div>
          </div>
          <Links />
          <div style={{ position: "relative" }}>
            <MainVideo />
          </div>
          <div className="info">
            <div className="row">
              <div className="column">
                <p>
                  Project Electric Sheep is a conversational dream simulator.
                  Tell Electric Sheep what you wish to dream about and dive into
                  a visual interpretation, where you talk about your dream with
                  virtual conversational characters in a 3D space.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <div className="pes-screenshot">
                  <Image
                    src={Screenshot1}
                    alt="Four characters in a group conversation, standing in front of mountainous terrain with tall columns"
                    fill
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                  />
                </div>
              </div>
              <div className="column">
                <div className="pes-description">
                  <p>
                    This project is an art experiment designed to hint at how AI
                    and creativity can work together to create dynamic worlds,
                    characters and experiences. It is an exploration of the
                    vital space between human-designed,
                    programmatically-generated content and generative AI
                    systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <div className="pes-description">
                  <p>
                    Explore your unique dreamworld, discover and speak to all
                    the virtual inhabitants of your dream, find Dall·E-generated
                    murals — and once you’re done finding out what one dream has
                    to offer, simply ask Project Electric Sheep to conjure up
                    something new.
                  </p>
                </div>
              </div>

              <div className="column">
                <div className="pes-screenshot">
                  <Image
                    src={Screenshot2}
                    alt="A temple-looking building nestled in snowy mountains"
                    fill
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                  />
                </div>
              </div>
            </div>
          </div>

          <Links />
          <br />
          <p>
            Project Electric Sheep is a production by{" "}
            <a
              href="https://charisma.ai"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#01ffff" }}
            >
              Charisma.ai
            </a>
            . Post your dreams on our social channels, and join our{" "}
            <a
              href="https://discord.com/invite/2KW3N4p"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#01ffff" }}
            >
              Discord
            </a>{" "}
            for the latest news and game updates!
          </p>
        </div>
        <Socials />
        <Footer />
      </div>
      <style jsx>{`
        a {
          color: #00e6ff;
        }

        p {
          font-size: 16px;
        }

        main {
          background: black;
          background-image: url("/static/background.png");
          background-position: center;
          background-size: cover;
          color: white;
          font-family: Bitbybit, sans-serif;
          font-weight: 400;
          font-size: 23px;
          line-height: 32px;
        }

        .darken {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          animation: change-color-anim 20s linear infinite;
        }

        @keyframes change-color-anim {
          0%,
          100% {
            background-color: rgba(0, 0, 0, 0.4);
          }
          50% {
            background-color: rgba(0, 0, 0, 0.33);
          }
        }

        .content {
          margin: 0 auto;
          padding: 14px;
          max-width: 1000px;
          padding: 10px;
        }

        .title {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 50px 0 10px;
        }

        .tagline {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 10px;
          justify-content: center;
        }

        .tag-text {
          margin: 18px 0;
          text-align: center;
        }

        @media (max-width: 768px) {
          .tag-text {
            font-size: 14px;
          }
        }

        .info {
          margin: 0 -12px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          margin: 15px 0;
        }

        .column {
          display: flex;
          flex-direction: column;
          flex-basis: 100%;
          flex: 1;
          margin: 0 15px;
        }

        .pes-screenshot {
          margin: 12px auto;
          display: flex;
          position: relative;
          width: 100vw;
          height: 100vh;
          max-width: 640px;
          max-height: 360px;
        }

        @media (max-width: 768px) {
          .pes-screenshot {
            max-width: 85vw;
            max-height: 47.8125vw;
          }
        }

        .pes-description {
          margin: 12px auto;
          position: relative;
          min-width: 180px;
        }

        @media screen and (min-width: 800px) {
          .column {
            flex: 1;
          }
        }
      `}</style>
    </main>
  );
}

export default HomePage;
