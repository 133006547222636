import { ReactNode } from "react";

type SocialIconLinkProps = {
  service: {
    href: string;
    ariaLabel: string;
    icon: ReactNode;
  };
};

function SocialIconLink({ service }: SocialIconLinkProps) {
  return (
    <div className="social-link">
      <a
        href={service.href}
        aria-label={service.ariaLabel}
        rel="noopener noreferrer"
        target="_blank"
      >
        {service.icon}
      </a>
      <style jsx>{`
        .social-link a {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 8px;
          background: rgba(0, 0, 0, 0.85);
          width: 48px;
          height: 48px;
          border-radius: 5px;
          border: solid 1px white;
          color: white;
          box-shadow: 0px 2px 10px #a4e7ff;
        }

        .social-link a:hover {
          color: white;
        }
      `}</style>
    </div>
  );
}

export default SocialIconLink;
